@import "../../styles/base";

.introArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  @include fromTablet {
    gap: 2rem;
  }
}

.list {
  @include fromDesktop {
    width: calc(100% - 270px);
    float: left;
  }
}

.intro {
  @include intro;
  margin: 0;
}