@import "/styles/base";

.operatedBy {
  order: 99;
  width: 100%;

  p {
    font-size: $font-m;
    margin: 0;
  }

  &.intro {
    display: none;
  }
  &.hotel {
    display: block;
    @include fromTablet {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
  &.room {
    @include fromTablet {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    @include fromDesktop {
      padding-left: 1rem;
      padding-left: 0;
    }
  }
  &.ticket {
    p {
      margin-top: 1rem;
      color: $grey-text;
      font-size: $font-sm-default;
      @include fromDesktop {
        text-align: center;
      }
    }
  }
  &.cart {
    p {
      font-size: $font-default;
    }
  }
}
