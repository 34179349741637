@import '../../../../styles/base';

.modal {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.btn {
  @include btn;
}
